<template>
  <Chart
    type="pie"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    labels: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    backgroundColor: {
      type: Array,
      default: () => []
    },
    hoverBackgroundColor: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.main.darkMode);
    const data = computed(() => {
      return {
        labels: props.labels,
        datasets: [
          {
            data: props.chartData,
            backgroundColor: props.backgroundColor,
            hoverBackgroundColor: props.hoverBackgroundColor,
            borderWidth: 5,
            borderColor: darkMode.value ? "#303953" : "#fff"
          }
        ]
      };
    });

    const options = computed(() => {
      return {
        legend: {
          display: false
        }
      };
    });

    return {
      data,
      options
    };
  }
});
</script>
