<template>
  <div>
    <VTitle :title="title" class="overview-title" />

    <VCard>
      <template #content>
        <VStatistics
          :statistics="statistics"
          :hide-legend="hideLegend"
          :chart-type="chartType"
          :colors="colors"
        />
      </template>
    </VCard>
  </div>
</template>

<script>
// Components
import VCard from "@/components/VCard";
import VTitle from "@/components/VTitle";
import VStatistics from "@/components/VStatistics";
// Constants
import chartTypes from "@/constants/chartTypes";

export default {
  components: {
    VCard,
    VTitle,
    VStatistics
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    statistics: {
      type: Object,
      default: () => ({})
    },
    hideLegend: {
      type: Boolean,
      default: false
    },
    chartType: {
      type: String,
      default: chartTypes.DONUT,
      validator: value => Object.values(chartTypes).includes(value)
    },
    colors: {
      type: Array,
      default: () => []
    }
  }
};
</script>
