<template>
  <div>
    <VTitle :title="$t('app.overview')" class="section-title" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else class="grid grid-cols-2 gap-8">
      <div class="col-span-2 lg:col-span-1">
        <VStatisticsCard
          :title="$t('app.learners', 2)"
          :statistics="learners"
        />
      </div>

      <div class="col-span-2 lg:col-span-1">
        <VStatisticsCard
          :title="$t('app.usage')"
          :statistics="usage"
          :colors="colors"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useCompetency from "@/composables/useCompetency";
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import VStatisticsCard from "@/components/VStatisticsCard";
// Constants
import resourceColors from "@/constants/resourceColors";

export default {
  components: {
    VTitle,
    VStatisticsCard
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    competency: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { isRequesting: isLoading, request: read } = useRequest();
    const { endpoint } = useCompetency();
    const { getText } = useTexts();

    // Data
    const data = ref({});

    // Computed
    const statistics = computed(() => data.value?.statistics ?? {});
    const learners = computed(() => statistics.value?.learners || {});
    const usage = computed(() => statistics.value?.usage || {});
    // eslint-disable-next-line
    const colors = computed(() => Object.keys(usage.value).map(x => resourceColors[x]));
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.overview")} - ${t("app.competencies", 2)} - ${t("app.teaching")}`;

      const name = getText(props.competency?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Methods
    const getOverview = async () => {
      const response = await read({
        endpoint: `${endpoint}.overview`,
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data;
    };

    // Lifecycle Hooks
    onMounted(() => {
      getOverview();
    });

    return {
      colors,
      documentTitle,
      isLoading,
      data,
      learners,
      usage
    };
  }
};
</script>
