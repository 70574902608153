<!--  eslint-disable -->
<template>
  <div>
    <div v-if="title" class="flex justify-center font-medium mb-4">
      {{ title }}
    </div>

    <div class="relative mb-4">
      <component
        v-if="hasValues"
        :is="getComponent(chartType)"
        :labels="labels"
        :chart-data="chartData"
        :background-color="displayedColors"
        :hover-background-color="displayedColors"
      />

      <div v-else class="circle-stats" />

      <div
        v-if="chartType === chartTypes.DONUT"
        class="flex pointer-events-none flex-col justify-center items-center absolute w-full h-full top-0 left-0"
      >
        <div class="text-2xl font-medium">
          {{ total }}
        </div>

        <div class="uppercase text-gray-600 dark:text-gray-600 mt-0.5">
          {{ $t("app.total") }}
        </div>
      </div>
    </div>

    <div v-if="!hideLegend" class="grid gap-y-4">
      <div
        v-for="(value, name, index) in statistics"
        :key="name"
        class="flex justify-between"
      >
        <div class="flex">
          <div
            class="self-center rounded-full w-3 h-3 mr-2"
            :style="{ 'background-color': displayedColors[index] }"
          />

          {{ $t(`backend.${name}`) }}
        </div>

        <div class="font-medium">
          {{ formatAmount(value, 0) }}
        </div>
      </div>
    </div>

    <VAlert v-if="hideLegend && !hasValues" :text="$t('app.no_statistics')" />
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
// Components
import DonutChart from "@/components/charts/donut-chart/Main";
import PieChart from "@/components/charts/pie-chart/Main";
import VAlert from "@/components/VAlert";
// Constants
import chartTypes from "@/constants/chartTypes";

export default {
  components: {
    DonutChart,
    PieChart,
    VAlert
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    statistics: {
      type: Object,
      default: () => ({})
    },
    chartType: {
      type: String,
      default: chartTypes.DONUT,
      validator: value => Object.values(chartTypes).includes(value)
    },
    hideLegend: {
      type: Boolean,
      default: false
    },
    colors: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { formatAmount } = useDisplay();
    const { getRGBStatusColor } = useColor();

    // Computed
    const displayedColors = computed(() => {
      if (props?.colors.length !== 0) {
        return props.colors;
      }

      return Object.keys(props.statistics).map(key => getRGBStatusColor(key));
    });

    const labels = computed(() => {
      return Object.keys(props.statistics).map(key => t(`backend.${key}`));
    });

    const chartData = computed(() => {
      return Object.values(props.statistics).map(value => {
        return formatAmount(value, 0);
      });
    });

    const hasValues = computed(() => {
      if (!props.statistics) {
        return false;
      }

      return Object.values(props.statistics).some(stat => stat);
    });

    const total = computed(() => {
      return Object.values(props.statistics).reduce((acc, cv) => {
        return acc + parseInt(cv);
      }, 0);
    });

    // Methods
    const getComponent = chartType => {
      const components = {
        [chartTypes.PIE]: "PieChart",
        [chartTypes.DONUT]: "DonutChart"
      };

      return components[chartType];
    };

    return {
      chartTypes,
      getComponent,
      total,
      displayedColors,
      formatAmount,
      chartData,
      labels,
      hasValues
    };
  }
};
</script>

<style scoped>
.circle-stats {
  border: solid 5px rgba(247, 250, 252, 1);
  border-radius: 50%;
  width: 100%;
  padding-bottom: 100%;
  background: white;
}
</style>
